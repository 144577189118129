"use client";

import { useEffect } from "react";
import { Button } from "react-bootstrap";

export default function Error({ error, reset }) {
  useEffect(() => {
    console.error(error);
  }, [error]);

  return (
    <div className="container py-5 d-flex flex-column align-items-center gap-3 justify-content-center">
      <h2>Something went wrong!</h2>
      <Button onClick={() => reset()}>Try again</Button>
    </div>
  );
}
